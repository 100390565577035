<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row">
          <vs-button v-if="userHasPermission('apps_customers_edit')" @click="addCompany" class="ml-4 sm:mb-0 mb-2">
            Add Company
          </vs-button>
          <!-- <div class="vx-col w-full">
            <div class="flex">
              <v-select style="width:30%" 
                label="label"
                :options="[
                  { code: 'johndoe@test.com', label: 'John Doe' },
                  { code: 'janedoe@test.com', label: 'Jane Doe' },
                  { code: 'MichaelJones@corporo.com', label: 'Michael Jones'},
                  { code: 'georgeholmes@abc.com', label: 'George Holmes'}]">
                <template #option="{ code, label }">
                  <div style="margin: 0"><strong>{{ label }}</strong></div>
                  <div class="text-sm text-muted">{{ code }}</div>
                </template>
              </v-select>
              <vs-button v-if="userHasPermission('apps_customers_edit')" @click="addUser" class="ml-4 sm:mb-0 mb-2">
                Add User
              </vs-button>
            </div>
          </div> -->
        </div>

        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="companies"
          :gridOptions="gridOptions"
          rowSelection="single"
          :context="context"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @grid-size-changed="onGridSizeChanged"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="5"
          v-model="currentPage" />
      </vx-card>

    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererActions from "./cell-renderers/CellRendererActions.vue";
import CellRendererActiveCheckbox from "./cell-renderers/CellRendererActiveCheckbox.vue";
import Vue from "vue";
import { format } from 'date-fns'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    AgGridVue,
    CellRendererActions,
    CellRendererActiveCheckbox
  },
  props: {
    applicationId: { required: true}
  },
  data() {
    return {      
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererActions,
        CellRendererActiveCheckbox,
      },
      searchQuery: "",
      showDeletePriceRulePopup: false,
      selectedReplacementPriceRule: {},
      companies: [],
      showAddModal: false,
      users: [
        { fullName: "John Doe", email: "johndoe@test.com" },
        { fullName: "Mike Blunt", email: "mikeblunt@test.com" },
        { fullName: "Joe Black", email: "joeblack@test.com" },
        { fullName: "Jane Doe", email: "janedoe@test.com" },
      ]
    };
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    preferedDateFormat() {      
      return this.$store.state.accountStore.generalSettings.dateFormat;
    },
    titleOptions () {
      return this.$store.state.lookups.titleOptions
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 250;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
    
  },
  created() {
    
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.$route.params.applicationId)) ) {
      this.$router.push('/error-404')
    }

    // this.companies = [
    //   { id: 1, name: 'Company 1', numberOfUsers: 4, isActive: true, createdDateTime: '2019-12-15'},
    //   { id: 2, name: 'Company 2', numberOfUsers: 2, isActive: true, createdDateTime: '2019-10-08'},
    //   { id: 3, name: 'Company 3', numberOfUsers: 7, isActive: false, createdDateTime: '2020-03-09'},
    //   { id: 4, name: 'Company 4', numberOfUsers: 12, isActive: true, createdDateTime: '2021-11-01'},
    // ]

     this.loadData()

  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs = [
      {
        headerName: "Actions",
        width: 150,
        sortable: false,
        suppressSizeToFit: true,
        cellRendererFramework: Vue.extend(CellRendererActions),
      },
      { 
        headerName: "ID",
        width: 100, 
        field: "id",
        suppressSizeToFit: true, 
      },
      {
        headerName: "Name",
        field: "name",
        width: 250,
        sortable: true,        
        suppressSizeToFit: false,
      },  
      {
        headerName: "Users",
        field: "numberOfUsers",
        width: 120,
        suppressSizeToFit: true,
      },
      { headerName: 'IsActive', field: 'isActive', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActiveCheckbox) }, 
      { 
        headerName: 'Created Date',
        field: 'createdDateTime',
        sortable: true,
        width: 180, 
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      }
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true,
    };

    this.context = { componentParent: this }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },

  methods: {
    loadData () {
      this.$vs.loading()
      this.$http.get(`api/b2b/companies/${this.applicationId}`)
      .then(response => {
          this.companies = response.data
      })
      .catch(error => console.error(error))
      .finally(() => this.$vs.loading.close()) 
    },
    activeFormatter(params){
      return params.data.isActive == 1 ? 'Active' : 'Inactive';
    },  
    addUser() {
      // this.showAddModal = true;
    },
    addCompany() {
      this.$router.push({
        name: "company-editor-add",
        params: { applicationId: this.applicationId },
      });
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
    },
    onGridSizeChanged(params) {
      if (params.clientWidth > 500) {
        this.gridApi.sizeColumnsToFit();
      } else {
        this.columnDefs.forEach(x => {
          x.width = 300;
        });
        this.gridApi.setColumnDefs(this.columnDefs);
      }
    },
    deleteCompany (companyId) {
      this.$vs.loading()
      this.$http.delete(`api/b2b/companies/${this.$route.params.applicationId}/${companyId}`)
      .then(response => {
          this.loadData();
          this.$_notifySuccess('Company successfully deleted');
      
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())  


    },
  },
};
</script>

<style>
</style>
